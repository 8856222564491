
import { email, required } from 'vuelidate/lib/validators/index.js';
import { mapActions } from 'vuex';

import { AUTH_LOGIN_MAGIC_LINK_SENT } from '@/components/auth/helpers/auth-component-names.js';
import EmailInput from '@/components/core/email-input/email-input.vue';
import FitButton from '@/components/fitover40/FitButton/FitButton.vue';

export default {
  name: 'Fo40LoginSite',
  validations: {
    email: {
      email,
      required,
    },
  },
  components: {
    FitButton,
    EmailInput,
  },
  data() {
    return {
      email: '',
      isLoading: false,
      emailDoesNotExist: false,
    };
  },
  mounted() {
    this.resetAuthData();
  },
  methods: {
    ...mapActions('auth', [
      'checkIfUserExistAction',
      'setUserLoginEmailAction',
      'setAuthComponentName',
      'loginAction',
      'resetAuthData',
    ]),
    emailHandler($event) {
      this.emailDoesNotExist = false;
      this.email = $event;
    },
    submitFormHandler() {
      this.isLoading = true;
      this.setUserLoginEmailAction(this.email);
      this.checkIfUserExistAction(this.email).then(({ exists }) => {
        if (!exists) {
          this.emailDoesNotExist = true;
          this.isLoading = false;
        } else {
          this.loginAction({
            email: this.email,
            redirectUrl: this.$route.query.redirect_url,
            redirectPath: this.$route.query.redirect_path,
          }).then(() => {
            this.setAuthComponentName(AUTH_LOGIN_MAGIC_LINK_SENT);
            this.isLoading = false;
          });
        }
      });
    },
  },
};
