
import { mapGetters } from 'vuex';

export default {
  ...mapGetters('payment', ['getUserEmail']),
  data() {
    return {
      email: '',
    };
  },
  mounted() {
    if (this.getUserEmail) this.email = this.getUserEmail;
  },
  methods: {
    commitEmail() {
      this.$emit('updateEmail', this.email);
    },
    emailHandler() {
      this.email = this.email.toLowerCase().trim();
      this.commitEmail();
    },
  },
};
